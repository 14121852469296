@font-face {
    font-family: 'STIXGeneralRegular';
    src: url('./src/FontStyles/STIXGeneralRegular.woff') format('woff');
    }

@font-face {
    font-family: 'Arno Pro';
    font-style: normal;
    font-weight: normal;
    src: url('./src/FontStyles/ArnoPro-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./node_modules/typeface-roboto/files/roboto-latin-300.woff')
      format('woff');
}

@font-face {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./node_modules/typeface-roboto/files/roboto-latin-400.woff')
        format('woff');
}

@font-face {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./node_modules/typeface-roboto/files/roboto-latin-500.woff')
        format('woff');
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: 'Raleway', sans-serif;
	vertical-align: baseline;
	box-sizing: border-box;
}


article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

#app-content {
	
  	letter-spacing: 1px;
  	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
}


@keyframes dash {
  from {
    stroke-dashoffset: 10000;
  }
  to {
    stroke-dashoffset: 4000;
  }
}

@keyframes fade {
  0% {
    fill-opacity: 0;
  }
  25% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 1; 
  }

  75% {
    fill-opacity: 1;
  }

  100% {
    fill-opacity: 0;
  }
}


.path {
  stroke-width: 4px;
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: dash 3s ease-in-out alternate infinite, fade 6s ease-in-out alternate infinite ; 
  fill: url(#img1);    
  fill-opacity: 1;
}

